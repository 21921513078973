import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.scss";
import classnames from "classnames";

const Slide = props => {
  const { image, portrait, name, header, children, className } = props;
  return (
    <div className={classnames(styles.slide, className && className)}>
      <div
        className={styles.imageContainer}
        style={{
          background: `url(${image}) no-repeat`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%"
        }}
      >
        <img
          src={image}
          style={{ visibility: "hidden" }}
          className={styles.image}
        />
      </div>
      <div className={styles.textContainer}>
        <img src={portrait} />
        <div className={styles.header}>
          <p className={styles.name}>{name}</p>
          <p className={styles.title}>{header}</p>
        </div>
        <div className={styles.text}>{children}</div>
      </div>
    </div>
  );
};

Slide.propTypes = {
  header: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  portrait: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Slide;
