import React from "react";
import styles from "./styles.scss";

const abc = require("../../../assets/img/abchus.png");
const fjell = require("../../../assets/img/fjellel.png");
const sterkstrom = require("../../../assets/img/sterkstrom.png");
const rectangle = require("./rectangle.png");

export class Partners extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <img src={sterkstrom} alt="" className={styles.img1} />
        {/*<img src={fjell} alt="" className={styles.img2} />*/}
        {/*<img src={abc} alt="" className={styles.img3} />*/}
        <p className={styles.text}>VÅRE KUNDER</p>
        <img src={rectangle} alt="" className={styles.rectangle} />
      </div>
    );
  }
}
