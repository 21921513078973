import React from "react";
import ArrowIcon from "./arrowIcon.svg";
import classnames from "classnames";
import styles from "./styles.scss";

const ArrowButton = ({ className, arrowClass, arrowIsRight, ...rest }) => {
  return (
    <button
      type="button"
      className={classnames(styles.container, className && className)}
      {...rest}
    >
      <ArrowIcon
        className={classnames(
          styles.arrow,
          arrowIsRight && styles.rightArrow,
          arrowClass
        )}
      />
    </button>
  );
};

export default ArrowButton;
