import React from "react";
import classnames from "classnames";
import styles from "./styles.scss";
import ArrowButton from "components/Walkthrough/ArrowBtn";
import Swipe from "components/Swipe";

const slides = [
  {
    img: require("./slide1.png"),
    title: "Logg inn i Elplan",
    text: (
      <p>
        For å opprette et prosjekt logger du inn med ditt personlige passord.
      </p>
    )
  },
  {
    img: require("./slide2.png"),
    title: "Opprett nytt prosjekt",
    text: (
      <p>
        For å opprette et prosjekt logger du inn med ditt personlige passord.
      </p>
    )
  },
  {
    img: require("./slide3.png"),
    title: "Grunnprosjektering",
    text: (
      <p>
        Last opp plantegninger og sett ut grunnprosjektering med priser,
        beskrivelser og bilder.
      </p>
    )
  },
  {
    img: require("./slide4.png"),
    title: "Lag tilvalgspakker",
    text: (
      <p>
        Basert på grunnprosjekteringen lages det oppgraderingspakker og
        tilvalgsmuligheter. Plantegningen gjøres enkelt interaktiv for kunde til
        å legge til produkter selv.
      </p>
    )
  },
  {
    img: require("./slide5.png"),
    title: "Se oversikt",
    text: (
      <p>
        Når grunnprosjekteringen er ferdig og tilvalgsmulighetene lagt inn går
        man over plantegningene og kvalitetskontrollerer.
      </p>
    )
  },
  {
    img: require("./slide6.png"),
    title: "Inviter boligeier",
    text: (
      <p>
        Til slutt inviteres kundene til løsningen. Man kan ha uendelig mange
        kunder per plantegning hvor alle automatisk får egne kontoer. Når de er
        ferdig får du beskjed og salget kan ferdigstilles.
      </p>
    )
  }
];

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideStep: 0,
      numSlides: Math.ceil(slides.length / 2)
    };
    this.slide = this.slide.bind(this);
  }
  slide(isLeft) {
    const { numSlides, slideStep } = this.state;
    const nextIndex = isLeft
      ? Math.min(numSlides - 1, slideStep + 1)
      : Math.max(0, slideStep - 1);
    this.setState({ slideStep: nextIndex });
  }
  renderPad() {
    return (
      <Swipe paginationStyles={styles.paginationStyles}>
        {slides.map((slide, i) => {
          return (
            <div key={i} className={styles.swiper}>
              <img src={slide.img} alt="" className={styles.slideImage} />
              <h3 className={styles.slideTitle}>{slide.title}</h3>
              <div className={styles.slideText}>{slide.text}</div>
            </div>
          );
        })}
      </Swipe>
    );
  }
  renderDesktop() {
    const { slideStep } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <ArrowButton
            className={classnames(styles.button, styles.leftButton)}
            onClick={() => this.slide(false)}
          />
          <div className={styles.slideList}>
            <div
              className={styles.slideContainer}
              style={{ transform: `translateX(-${slideStep * 100}%)` }}
            >
              {slides.map((slide, i) => (
                <div className={styles.slide} key={i}>
                  <img src={slide.img} alt="" className={styles.slideImage} />
                  <h3 className={styles.slideTitle}>{slide.title}</h3>
                  <div className={styles.slideText}>{slide.text}</div>
                </div>
              ))}
            </div>
          </div>
          <ArrowButton
            className={classnames(styles.button, styles.rightButton)}
            arrowIsRight
            onClick={() => this.slide(true)}
          />
        </div>
      </div>
    );
  }
  render() {
    const { slideStep } = this.state;
    const { isPad } = this.props;
    return isPad ? this.renderPad() : this.renderDesktop();
  }
}
