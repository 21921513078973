import React from "react";
import classnames from "classnames";
import Swiper from "swiper/dist/js/swiper.js";
import { uniqueId } from "lodash";
import styles from "./styles.scss";
import "./swiper.scss";

export default class extends React.Component {
  static defaultProps = {
    options: {}
  };

  constructor(props) {
    super(props);
    this.onResize = this.onResize.bind(this);
    this.className = uniqueId("swiper-");
    this.swiper;
  }

  componentDidMount() {
    this.swiper = new Swiper(`.${this.className}`, {
      loop: true,
      pagination: { el: ".swiper-pagination" },
      paginationClickable: true,
      ...this.props.options
    });
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    setTimeout(() => this.swiper.update(true), 0);
  }

  render() {
    const { style, className, white, paginationStyles } = this.props;
    return (
      <div
        className={classnames(
          "swiper-container",
          this.className,
          styles.swiper,
          className,
          white && styles.white
        )}
        style={style}
      >
        <div className="swiper-wrapper">
          {this.props.children.map((child, index) => (
            <div key={index} className="swiper-slide">
              {child}
            </div>
          ))}
        </div>
        <div
          className={classnames(
            "swiper-pagination",
            paginationStyles && paginationStyles
          )}
        />
      </div>
    );
  }
}
