import React from "react";
import styles from "./styles.scss";
import classnames from "classnames";
import { Fragment } from "react";

const iPadWidth = 1366;

export class InfoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { innerWidth: null };
  }

  setInnerWidth = () => {
    const { innerWidth } = window;
    this.setState({ innerWidth });
  };

  componentWillMount() {
    this.setInnerWidth();
    window.addEventListener("resize", this.setInnerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setInnerWidth);
  }

  render() {
    const innerWidth = this.state.innerWidth;
    const isIpad = innerWidth <= iPadWidth;
    const { title, src, className, children, index, id } = this.props;
    return (
      <div className={classnames(styles.container, className)}>
        {(index % 2 != 0 || isIpad) && (
          <Fragment>
            <img src={src} alt="" className={styles.image} />
            <div className={styles.textContainer} id={styles.margin}>
              <h2 className={styles.title}>{title}</h2>
              <div className={styles.text}>{children}</div>
              <a href="#" id={id}>
                Watch video
              </a>
            </div>
          </Fragment>
        )}
        {index % 2 === 0 &&
          !isIpad && (
            <Fragment>
              <div className={styles.textContainer}>
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.text}>{children}</div>
                <a href="#" id={id}>
                  Watch video
                </a>
              </div>
              <img
                src={src}
                alt=""
                className={styles.image}
                id={styles.margin}
              />
            </Fragment>
          )}
      </div>
    );
  }
}
