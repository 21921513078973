import React from "react";
import { Fragment } from "react";
import styles from "./styles.scss";
import ReactGA from "react-ga";
import DotIcon from "./dot.svg";

const IPAD_BREAK_POINT = 1366;
const IPHONE_BREAK_POINT = 414;

export class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      innerWidth: null,
      isTop: window.scrollY
    };
    this.setInnerWidth = this.setInnerWidth.bind(this);
  }

  setInnerWidth() {
    const { innerWidth } = window;
    this.setState({ innerWidth });
  }

  handleScroll = () => {
    this.setState({ isTop: window.scrollY });
  };

  componentWillMount() {
    this.setInnerWidth();
    window.addEventListener("resize", this.setInnerWidth);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setInnerWidth);
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const isIpad = this.state.innerWidth <= IPAD_BREAK_POINT;
    const isMobile = this.state.innerWidth <= IPHONE_BREAK_POINT;

    return (
      <Fragment>
        <div className={styles.container}>
          {/*<a
                  href="mailto:bang@uppercase.no"
                  className={styles.pilot}
                  onClick={() => {
                      ReactGA.event({
                          category: "Navigation",
                          action: "Clicked Be Pilot"
                      });
                  }}
              >
                  <DotIcon className={styles.dot}/>
                  BLI PILOTBRUKER
              </a>*/}
          {/*<a
                href="#"
                className={styles.support}
                onClick={() => {
                    ReactGA.event({
                        category: "Navigation",
                        action: "Clicked Support"
                    });
                }}
            >
                <DotIcon className={styles.dot}/>
                SUPPORT
            </a>*/}
          {(!isIpad || isMobile) &&
            !(this.state.isTop <= 0.5 * window.innerHeight) && (
              <button className={styles.login}>
                <a
                  href="https://el.boligtilvalg.no"
                  className={styles.loginlink}
                >
                  LOGG INN
                </a>
              </button>
            )}
        </div>
        {isIpad &&
          !isMobile && (
            <button className={styles.login}>
              <a href="https://el.boligtilvalg.no" className={styles.loginlink}>
                LOGG INN
              </a>
            </button>
          )}
      </Fragment>
    );
  }
}
