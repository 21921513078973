import React, { Fragment } from "react";
import { hot } from "react-hot-loader";
import "assets/scss/main.scss";
import styles from "./styles.scss";

import { Welcome } from "../Welcome";
import { Header } from "../Header";
import { Navbar } from "../Navbar";
import { Partners } from "../Partners";
import { Services } from "../Services";
import { Slider } from "../Slider";
import { Demo } from "../Demo";
import { Trynow } from "../Trynow";
import { Footer } from "../Footer";
import Walkthrough from "../Walkthrough";

import PaddingContainer from "components/App/common/PaddingContainer";

import ReactGA from "react-ga";
import { Information } from "components/Information";

ReactGA.initialize("UA-121819055-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const SWIPE_BREAK_POINT = 768;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      innerWidth: null
    };
    this.setInnerWidth = this.setInnerWidth.bind(this);
  }

  setInnerWidth() {
    const { innerWidth } = window;
    this.setState({ innerWidth });
  }

  componentWillMount() {
    this.setInnerWidth();
    window.addEventListener("resize", this.setInnerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setInnerWidth);
  }

  renderComponents() {
    const isMobile = this.state.innerWidth <= SWIPE_BREAK_POINT;
    const isPad = this.state.innerWidth <= 1024;

    return (
      <Fragment>
        {isMobile && <Welcome />}
        <PaddingContainer>
          {!isMobile && <Welcome />}
          {isMobile && <Demo name="demo" />}
          <Partners />
          <Services name="services" />
          <Walkthrough isPad={isPad} />
        </PaddingContainer>
        <Slider />
        <PaddingContainer>
          <Information />
          {!isMobile && <Demo name="demo" />}
          <Trynow />
        </PaddingContainer>
      </Fragment>
    );
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <Header />
          <Navbar />
        </div>
        {this.renderComponents()}
        <Footer />
      </div>
    );
  }
}

export default hot(module)(App);
