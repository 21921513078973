import React from "react";
import styles from "./styles.scss";
import Boligtilvalg from "./Boligtilvalg.svg";

const symbol = require("../../../assets/img/header-symbol.png");

export const Header = () => (
  <div className={styles.container} onClick={() => window.scrollTo(0, 0)}>
    <img alt="symbol" className={styles.symbol} src={symbol} />
    <Boligtilvalg className={styles.boligtilvalg} />
  </div>
);
