import React from "react";
import styles from "./styles.scss";
import ReactGA from "react-ga";

export const Footer = () => (
  <div className={styles.container}>
    <div className={styles.text}>
      <a
        id={styles.email}
        href="mailto:bang@uppercase.no"
        onClick={() => {
          ReactGA.event({ category: "Navigation", action: "Clicked Email" });
        }}
      >
        bang@uppercase.no
      </a>
      <br />
      <a
        id={styles.number}
        href="tel:+4745220875"
        onClick={() => {
          ReactGA.event({
            category: "Navigation",
            action: "Clicked Phone number"
          });
        }}
      >
        +47 915 12 021
      </a>
    </div>
    <p className={styles.copyright}>All rights reserved to Boligvalg AS</p>
  </div>
);
