import React from "react";
import styles from "./styles.scss";
import { InfoComponent } from "components/Information/InfoComponent";

const MOBILE_BREAK_POINT = 768;

export class Information extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      innerWidth: null
    };
    this.setInnerWidth = this.setInnerWidth.bind(this);
  }

  setInnerWidth() {
    const { innerWidth } = window;
    this.setState({ innerWidth });
  }

  componentWillMount() {
    this.setInnerWidth();
    window.addEventListener("resize", this.setInnerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setInnerWidth);
  }

  render() {
    const isMobile = this.state.innerWidth <= MOBILE_BREAK_POINT;

    return (
      <div className={styles.container}>
        <InfoComponent
          title="Prosjekter direkte i digital planskisse!"
          src={
            isMobile ? require("./img/ipadimg1.png") : require("./img/img1.png")
          }
          className={styles.info}
          id={styles.hidden}
          index={1}
        >
          <p>
            Som prosjektleder eller montør så vil du kunne gjøre prosjekteringen
            direkte i Elplan. På den måten vil du ha alt på en plass og enkelte
            kunne gjøre endringer som speiles direkte ut til sluttkunden.
          </p>

          <p>
            Last opp plantegningen, prosjekteringen og legg inn tilvalgene du
            vil tilby. Alt på ett sted, mindre forvirring, færre misforståelser
            og mye tid spart.
          </p>
        </InfoComponent>

        <InfoComponent
          title="Hold orden på kjøpere og tilvalg"
          src={require("./img/img2.png")}
          className={styles.info}
          id={styles.hidden}
          index={2}
        >
          <p>
            Det kan være frustrerende å holde orden på alle kommunikasjon med
            kjøpere og deres ønsker. Med Elplan vil du alltid ha full oversikt
            over status. På denne måten vil det bli større fokus på salg av
            tilvalg.
          </p>
        </InfoComponent>
        <InfoComponent
          title="Design hjemmet ditt fra sofakroken!"
          src={
            isMobile ? require("./img/ipadimg3.png") : require("./img/img3.png")
          }
          className={styles.info}
          id={styles.hidden}
          index={3}
        >
          <p>
            Med Elplan får du større fleksibilitet i prosessen med å velge
            tilvalg. En prosess på dine premisser. Bestem tilvalg pr rom. Velg
            enkelt blant pakker med ulike pris og kvalitet. Gjør det når du vil
            og hvor du vil - gjerne fra sofakroken med din kjære!
          </p>
        </InfoComponent>
      </div>
    );
  }
}
