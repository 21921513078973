import React from "react";
import { Fragment } from "react";
import styles from "./styles.scss";

import Service from "components/Services/Service";
import { Trynow } from "components/Trynow";

export const Services = ({ name }) => (
  <Fragment>
    <a name={name} />
    <div className={styles.container}>
      <p className={styles.text}>Hva kan Elplan gjøre for deg?</p>
      <div className={styles.serviceContainer}>
        <Service
          header="SPARE TID PÅ PROSJEKTERING"
          image={require("./img/projecthandling.png")}
          className={styles.service}
        >
          <p>
            Last opp plantegning, utfør prosjekteringen og sy sammen dine
            tilvalgspakker samtidig.
          </p>
        </Service>
        <Service
          header="FORENKLE KOMMUNIKASJONEN MED KUNDE"
          image={require("./img/clientmessages.png")}
          className={styles.service}
          id={styles.id}
        >
          <p>
            Loggført dialog mellom sluttkunde, saksbehandler og utførende
            installatør minimerer uenigheter i prosessen.
          </p>
        </Service>
        <Service
          header="GJØR TILVALG ROM FOR ROM"
          image={require("./img/projectediting.png")}
          className={styles.service}
        >
          <p>
            Lag tilvalgspakker rom-for-rom som dine kunder kan velge mellom.
          </p>
        </Service>
        <Service
          header="GJØRE TILVALG FRA SOFAEN"
          image={require("./img/handleonline.png")}
          className={styles.service}
        >
          <p>
            Som boligkjøper kan man enkelt ta alle beslutninger tilknyttet
            el-tilvalg hjemme fra sofaen. Både du og boligkjøper sparer tid på
            møter og telefoner.
          </p>
        </Service>
        <Service
          header="SELGE FLERE TILVALG"
          image={require("./img/clientupgrades.png")}
          className={styles.service}
        >
          <p>
            Selg flere tilvalg ved å inspirere og informerer dine kunder om de
            forskjellige produktene du tilbyr.
          </p>
        </Service>
        <div className={styles.service}>
          <Trynow
            id={styles.trynow}
            textId={styles.textId}
            buttonId={styles.buttonId}
            symbolId={styles.symbolId}
          />
        </div>
      </div>
    </div>
  </Fragment>
);
