import React, { Fragment } from "react";
import styles from "./styles.scss";

export const Demo = ({ name }) => (
  <Fragment>
    <a name={name} />
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <video width="100%" height="100%" controls>
          <source src={require("./Elplan_demo.mp4")} type="video/mp4" />
        </video>
      </div>
    </div>
  </Fragment>
);
