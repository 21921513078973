import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.scss";
import classnames from "classnames";

const Service = props => {
  const { image, header, children, className, id } = props;
  return (
    <div className={classnames(styles.service, className && className)}>
      <img src={image} alt="Service" className={styles.image} />
      <h2 className={styles.header}>{header}</h2>
      <div className={styles.text} id={id}>
        {children}
      </div>
    </div>
  );
};

Service.propTypes = {
  header: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Service;
