import React from "react";
import styles from "./styles.scss";
import ReactGA from "react-ga";
import classnames from "classnames";
import Arrow from "./arrow.svg";

export const Trynow = props => {
  const { id, textId, buttonId, symbolId } = props;
  return (
    <div className={styles.container} id={id}>
      <Arrow className={styles.symbol} id={symbolId} />
      <div className={styles.text} id={textId}>
        <p className={styles.header}>Vil du teste Elplan?</p>
      </div>
      <a
        href="mailto:bang@uppercase.no"
        className={styles.button}
        id={buttonId}
        onClick={() => {
          ReactGA.event({
            category: "Navigation",
            action: "Clicked Try button"
          });
        }}
      >
        Prøv nå
      </a>
    </div>
  );
};
