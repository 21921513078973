import React from "react";
import styles from "./styles.scss";
import ArrowIcon from "./arrow.svg";
import ReactGA from "react-ga";

const half = require("assets/img/shadow_half.png");
const full = require("assets/img/shadow_full.png");
const orangesym = require("assets/img/orangesymbol.png");

export const Welcome = () => (
  <div className={styles.container}>
    <div className={styles.inner}>
      <div className={styles.logos}>
        <img alt="symbols" src={orangesym} className={styles.orangesym} />
      </div>

      <div className={styles.text}>
        <p>
          Elplan er et tidsbesparende digitalt <span>verktøy</span> for
          prosjektering og salg
        </p>

        <div className={styles.userContainer}>
          <a
            href="mailto:bang@uppercase.no"
            className={styles.tryButton}
            onClick={() => {
              ReactGA.event({
                category: "Navigation",
                action: "Clicked Try button"
              });
            }}
          >
            Bli pilotbruker
          </a>
          <a
            href="https://el.boligtilvalg.no"
            className={styles.login}
            onClick={() => {
              ReactGA.event({
                category: "Navigation",
                action: "Clicked Login button"
              });
            }}
          >
            LOGG INN
          </a>
        </div>
      </div>

      <a
        href="#services"
        className={styles.demoButton}
        onClick={() => {
          ReactGA.event({
            category: "Navigation",
            action: "Clicked Demo button"
          });
        }}
      >
        LES MER
      </a>
      <ArrowIcon className={styles.arrow} />
    </div>
  </div>
);
