import React, { Component, Fragment } from "react";
import styles from "./styles.scss";
import Swipe from "components/Swipe";

import Slide from "components/Slider/Slide";

const arrowright = require("./img/arrowright.png");
const arrowleft = require("./img/arrowleft.png");

const SWIPE_BREAK_POINT = 1366;

export class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 1,
      innerWidth: null
    };
    this.setInnerWidth = this.setInnerWidth.bind(this);
  }

  setInnerWidth() {
    const { innerWidth } = window;
    this.setState({ innerWidth });
  }

  componentWillMount() {
    this.setInnerWidth();
    window.addEventListener("resize", this.setInnerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setInnerWidth);
  }

  nextSlide() {
    const slide = document.getElementById("styles---slideContainer---ItRBe");
    if (this.state.index != 2) {
      this.setState({ index: this.state.index + 1, shift: 100 });
      slide.style.justifyContent = "flex-end";
    }
  }

  prevSlide() {
    const slide = document.getElementById("styles---slideContainer---ItRBe");
    if (this.state.index != 1) {
      this.setState({ index: this.state.index - 1 });
      slide.style.justifyContent = "flex-start";
    }
  }

  renderSlides() {
    return [
      <Slide
        key={1}
        name="Morten"
        header="Elektriker hos Sterkstrøm"
        portrait={require("./img/portrait2.png")}
        image={require("./img/meeting.png")}
        className={styles.slide1}
      >
        <p>
          " Ved å samle alle ledd fra grunnprosjektering til sluttmontering i
          ett og samme program sparer jeg tid og får mye bedre oversikt og
          kontroll. "
        </p>
      </Slide>,
      <Slide
        key={2}
        name="Tommy"
        header="Kunde hos Sterkstrøm som bruker Elplan"
        portrait={require("./img/portrait.png")}
        image={require("./img/couple.png")}
        className={styles.slide2}
      >
        <p>
          "Det var flott å kunne definere alle oppgraderingene hjemme, i stedet
          for å måtte bruke masse tid i møter."
        </p>
      </Slide>
    ];
  }

  render() {
    const { innerWidth } = this.state;
    return (
      <div className={styles.container}>
        {innerWidth <= SWIPE_BREAK_POINT ? (
          <Swipe>{this.renderSlides()}</Swipe>
        ) : (
          <Fragment>
            <div id={styles.slideContainer}>{this.renderSlides()}</div>
            <div className={styles.navigate}>
              <img
                src={arrowleft}
                alt=""
                className={styles.arrowleft}
                onClick={this.prevSlide.bind(this)}
              />
              <div className={styles.indexContainer}>
                <div className={styles.index}>{this.state.index}</div>
                <div className={styles.ratio}>/ 2</div>
              </div>
              <img
                src={arrowright}
                alt=""
                className={styles.arrowright}
                onClick={this.nextSlide.bind(this)}
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
